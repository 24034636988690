import * as Yup from 'yup';

let createAccountValidation = Yup.object().shape({
	name: Yup.string().required('Name is required'),
	username: Yup.string().required('Username is required'),
	email: Yup.string().email('Invalid Email').required('Email is required'),
	password: Yup.string().min(8, 'Password must be of minimum 8 letters').required(),
	password2: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
		.required('Password is required'),
});

export default createAccountValidation;
