import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { TOAST_CONFIGS, CustomToaster } from "./utils/toast";
import Routes from "./routes";
import { Helmet } from "react-helmet";

const getFaviconPath = (isDarkMode = false) => {
  return `/favicon${isDarkMode ? "-dark" : ""}.ico`;
};

function App() {
  const [faviconHref, setFaviconHref] = useState("/favicon.png");
  useEffect(() => {
    // Get current color scheme.
    const matcher = window.matchMedia("(prefers-color-scheme: dark)");
    // Set favicon initially.
    setFaviconHref(getFaviconPath(matcher.matches));
    // Change favicon if the color scheme changes.
    matcher.onchange = () => setFaviconHref(getFaviconPath(matcher.matches));
  }, [faviconHref]);

  return (
    <Fragment>
      <Helmet>
        <link rel="icon" href={faviconHref} />
      </Helmet>
      <BrowserRouter>
          <Routes />
          <Toaster {...TOAST_CONFIGS} children={CustomToaster} />
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
