import { Link, useRoutes } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import {BiError} from "react-icons/bi";

function Router() {
  const routes = useRoutes( PRIVATE_ROUTES);
  return routes;
}

export default Router;

export const PRIVATE_ROUTES = [
    {
        path: '/',
        element: <Login/>,
    },
    {
        path: '/register',
        element: <Register/>
    },
    {
        path: '*',
        element: (
            <div className="no-page">
                <BiError/>
                <span>No Page Found</span>
                <Link to="/">Go To Home</Link>
            </div>
        ),
    },
];
