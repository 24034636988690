import axios from 'axios';
import LocalStorage from '../utils/localStorage';
import { API_BASE_URL } from '../constants';

const Axios = axios.create({
	baseURL: API_BASE_URL,
});

// Add a request interceptor
Axios.interceptors.request.use(
	function (config) {
		config.headers = {
			...config.headers,
			Authorization: `Bearer ${LocalStorage.getItem('token')}`,
		};

		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

export default Axios;
