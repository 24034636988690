import { createContext, useState } from "react";
import localStorage from "../utils/localStorage";

export const UserContext = createContext({
  user: {
    userData: {},
    isAuthenticated: false,
  },
  login: () => {},
  logout: () => {},
});

const INITIAL_STATE = {
  userData: {},
  token: null,
  isAuthenticated: false,
};

const initialState = () => {
  const userData = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  if (userData && token) {
    return {
      ...userData,
      token,
      isAuthenticated: true,
    };
  } else {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("redirect");
  }
  return INITIAL_STATE;
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(initialState);

  const login = (data) => {
    localStorage.setItem("user", data.user);
    localStorage.setItem("token", data.access_token);

    setUser({
      ...data.user,
      token: data.access_token,
      isAuthenticated: true,
    });
  };

  const logout = () => {
    setUser({ ...INITIAL_STATE });
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("redirect");
  };

  return (
    <UserContext.Provider
      value={{ user, logout, login }}
    >
      {children}
    </UserContext.Provider>
  );
};
