import "./../styles/login.scss";
import { useFormik } from "formik";
import loginValidation from "./../validations/login";
import {
  forgotPasswordOtpGen,
  resendVerificationCode,
  signIn,
  verifyForgotPasswordOtp,
  verifyUserOtp,
} from "./../service/userService";
import toast from "./../utils/toast";
import { Fragment, useState, useEffect, useContext } from "react";
import OtpInput from "react-otp-input";
import forgotPasswordValidation from "./../validations/forgotPassword";
import { validatePassword } from "./../utils/utils";
import { UserContext } from "../context/user.context";
import { Link, useLocation } from "react-router-dom";
import { checkValidAuth, logoutCurrentUser } from "../service/service.user";
import { SUCCESS200, SUCCESS201 } from "../constants";
import MainLoader from "./components/MainLoader";
import { BiLoaderAlt } from "react-icons/bi";
import localStorage from "../utils/localStorage";
import { HiOutlineHome } from "react-icons/hi";
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import { AiOutlineCloud, AiOutlineLogout } from "react-icons/ai";
import Logo from "../assets/img/logo-white.png";

const Login = () => {
  const [isForgot, setIsForgot] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customError, setCustomError] = useState({});
  const [verificationCode, setVerificationCode] = useState(0);
  const [userId, setUserId] = useState(0);
  const [timer, setTimer] = useState(0);
  const [showVerification, setShowVerification] = useState(false);
  const [email, setEmail] = useState("");
  const [intervalTime, setIntervalTime] = useState(null);
  const { login, user, logout } = useContext(UserContext);
  const [isAuth, setIsAuth] = useState(false);
  const [loadFullScreen, setLoadFullScreen] = useState(false);
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);

  useEffect(() => {
    if (queryParameters.get("redirect")) {
      localStorage.setItem("redirect", queryParameters.get("redirect"));
    }
    if (user.isAuthenticated) {
      setLoadFullScreen(true);
      checkValidAuth()
        .then((res) => {
          if (
            (res.status === SUCCESS200 || res.status === SUCCESS201) &&
            res.data &&
            res.data.status === SUCCESS200
          ) {
            const redirect = localStorage.getItem("redirect");
            if (redirect) {
              localStorage.removeItem("redirect");
              const token = localStorage.getItem("token");
              window.location.href = `${redirect}/sso/${token}`;
            } else {
              setIsAuth(true);
              setLoadFullScreen(false);
            }
          } else {
            setIsAuth(false);
            logout();
            setLoadFullScreen(false);
          }
        })
        .catch((err) => {
          setIsAuth(false);
          logout();
          setLoadFullScreen(false);
        });
    }
  }, [user.isAuthenticated]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginValidation,

    onSubmit: async (data) => {
      setIsLoading(true);
      await signIn(data)
        .then((response) => {
          login(response.data);
          setIsLoading(false);
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.data &&
            e.response.data.data.user_id &&
            e.response.data.data.msg === "verify"
          ) {
            setUserId(e.response.data.data.user_id);
            setEmail(data.email);
            setShowVerification(true);
            setIsLoading(false);
          } else {
            toast.error("Invalid credentials!");
            setIsLoading(false);
          }
        });
    },
  });

  const { handleSubmit, touched, errors, getFieldProps } = formik;

  const onClickForgot = () => {
    setIsForgot(!isForgot);
  };

  const submitVerification = async () => {
    setIsLoading(true);
    await verifyUserOtp({
      verification_code: verificationCode,
      user_id: userId,
    })
      .then(async (res) => {
        if (res.status === 200 && res.data && res.data.user) {
          toast.success("Verification completed!");
          login(res.data);
          setIsLoading(false);
        } else if (res.data && res.data.error) {
          setCustomError(res.data.error);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const resendOtp = async () => {
    if (timer !== 0) {
      return;
    }
    await resendVerificationCode({ user_id: userId })
      .then((res) => {
        toast.success("Code sent successfully!");
        setTimer(60);
        let timer = 60;
        const interval = setInterval(() => {
          if (timer === 1) {
            setTimer(0);
            clearInterval(intervalTime);
            setIntervalTime(null);
          } else {
            timer--;
            setTimer(timer);
          }
        }, 1000);
        setIntervalTime(interval);
      })
      .catch((err) => {
        toast.error("Unable to resend code!");
        console.log(err);
      });
  };

  const ssoTo = (type) => {
    const token = localStorage.getItem("token");
    if (token) {
      if (type === 1) {
        window.location.href =
          (window.location.host.includes("localhost:")
            ? `http://localhost:3000/`
            : `https://creatorstock.io/`) + `sso/${token}`;
      } else {
        window.location.href =
          (window.location.host.includes("localhost:")
            ? `http://localhost:3000/`
            : `https://cloud.creatorstock.io/`) + `sso/${token}`;
      }
    }
  };

  const logoutUser = async () => {
    setLoadFullScreen(true);
    await logoutCurrentUser();
    setIsAuth(false);
    logout();
    setLoadFullScreen(false);
  };

  return (
    <div className="upload-popup-wrapper  base-modal  login">
      {loadFullScreen ? (
        <MainLoader />
      ) : isAuth ? (
        <div className="upload-popup-cnt">
          <button className={"logout-button"} onClick={logoutUser}>
            <AiOutlineLogout />
          </button>
          <div className="full">
            <div className={"select-dashboard"}>
              <img src={Logo} alt="loading logo" className={"logo-icon"} />
              <h2>
                <span>CreatorStock</span>
              </h2>
              <p>Please choose the Dashboard you want to visit</p>

              <div className="full-part">
                <div
                  className="card mt-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => ssoTo(1)}
                >
                  <div className={"top-icon"}>
                    <HiOutlineHome />
                  </div>
                  <span>CreatorStock Home</span>
                  <div>
                    <BsArrowLeftCircle size={"20px"} />
                  </div>
                </div>
                <div
                  className="card mt-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => ssoTo(2)}
                >
                  <div className={"top-icon"}>
                    <AiOutlineCloud />
                  </div>
                  <span>CreatorStock Cloud</span>
                  <div>
                    <BsArrowRightCircle size={"20px"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="upload-popup-cnt">
          <div className="full">
            <div className={"form-card"}>
              {isForgot ? (
                <ForgotPassword onClickForgot={onClickForgot} />
              ) : !showVerification ? (
                <form onSubmit={handleSubmit}>
                  <img src={Logo} alt="loading logo" className={"logo-icon"} />
                  <h2>
                    Welcome to <span>CreatorStock</span>
                  </h2>
                  <p>Please login to your account</p>

                  <div className="full-part">
                    <div className="input-block">
                      <label>Email</label>
                      <input
                        className={
                          errors.email && touched.email && `invalid-border`
                        }
                        type="email"
                        placeholder="Email"
                        {...getFieldProps("email")}
                      />
                      <span
                        className={`helper-text ${
                          Boolean(errors.email && touched.email) &&
                          `invalid-color`
                        }`}
                      >
                        {touched.email && errors.email}
                      </span>
                    </div>
                  </div>

                  <div className="full-part my-2">
                    <div className="input-block">
                      <label>Password</label>
                      <input
                        type="password"
                        placeholder="Password"
                        className={
                          errors.password &&
                          touched.password &&
                          `invalid-border`
                        }
                        {...getFieldProps("password")}
                      />
                      <span
                        className={`helper-text ${
                          Boolean(errors.password && touched.password) &&
                          `invalid-color`
                        }`}
                      >
                        {touched.password && errors.password}
                      </span>
                    </div>
                  </div>

                  <span
                    onClick={onClickForgot}
                    className={"forgotBtn"}
                    style={{ cursor: "pointer" }}
                  >
                    Forgot Password?
                  </span>
                  <div className="input-block action-col">
                    <button
                      type="submit"
                      className="button-container"
                      disabled={isLoading}
                    >
                      {isLoading && <BiLoaderAlt />} Login
                    </button>
                  </div>
                  <div className="sign">
                    Don't have an account?
                    <Link to="/register">Sign Up</Link>
                  </div>
                </form>
              ) : (
                <Fragment>
                  <h2>
                    Account <span>Verification</span>
                  </h2>
                  <p>Please enter the 6 digit code sent to {email}</p>
                  <div className="full-part">
                    <div className="input-block" style={{ margin: "0 auto" }}>
                      <OtpInput
                        value={verificationCode}
                        onChange={(value) => setVerificationCode(value)}
                        numInputs={6}
                        renderSeparator={
                          <span style={{ marginRight: "10px" }} />
                        }
                        inputStyle={{ width: "50px" }}
                        renderInput={(props) => <input {...props} />}
                      />

                      <span
                        className={`helper-text ${
                          customError.verification_code && `invalid-color`
                        }`}
                      >
                        {customError.verification_code}
                      </span>
                    </div>
                    <div
                      className="input-block action-col mt-3"
                      style={{ margin: "0 auto" }}
                    >
                      <button
                        type="button"
                        className="button-container"
                        disabled={isLoading}
                        onClick={submitVerification}
                      >
                        {isLoading && <BiLoaderAlt />} Verify
                      </button>
                    </div>
                    <div className={"resendBtn"}>
                      Didn’t receive code?
                      <a onClick={resendOtp}>{timer || " Resend Code"}</a>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ForgotPassword = ({ onClickForgot }) => {
  const [customErrors, setCustomErrors] = useState({});
  const [verificationCode, setVerificationCode] = useState(0);
  const [userId, setUserId] = useState(0);
  const [timer, setTimer] = useState(0);
  const [showVerification, setShowVerification] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [intervalTime, setIntervalTime] = useState(null);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  useEffect(() => {
    setIsLoading(false);
    setCustomErrors({});
    setShowVerification(false);
    setUserId(0);
    setVerificationCode(0);
    setTimer(0);
    setIntervalTime(null);
    setEmail("");
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordValidation,

    onSubmit: async (data) => {
      setIsLoading(true);
      setEmail(data.email);
      forgetSubmit(data.email);
    },
  });

  const forgetSubmit = async (email) => {
    if (timer !== 0) {
      return;
    }
    await forgotPasswordOtpGen({ email })
      .then((res) => {
        console.log(res.data);
        if (
          res.status === 200 &&
          res.data &&
          res.data.status === 200 &&
          res.data.id
        ) {
          setUserId(res.data.id);
          setShowVerification(true);
          toast.success("Code sent successfully!");
          setTimer(60);
          let timer = 60;
          const interval = setInterval(() => {
            if (timer === 1) {
              setTimer(0);
              clearInterval(intervalTime);
              setIntervalTime(null);
            } else {
              timer--;
              setTimer(timer);
            }
          }, 1000);
          setIntervalTime(interval);
        } else {
          if (res.data.error) {
            setCustomErrors(res.data.error);
          } else {
            toast.error("Unable to send the code!");
          }
        }
      })
      .catch((err) => {
        toast.error("Unable to send the code!");
        console.log(err);
      })
      .finally(setIsLoading(false));
  };

  const submitVerification = async () => {
    setIsLoading(true);
    const validation = await validate();
    if (validation) {
      await verifyForgotPasswordOtp({
        password,
        password2,
        verification_code: verificationCode,
        user_id: userId,
      })
        .then(async (res) => {
          if (res.status === 200 && res.data && res.data.status === 200) {
            setCustomErrors({});
            setShowVerification(false);
            setUserId(0);
            setVerificationCode(0);
            setTimer(0);
            setIntervalTime(null);
            setEmail("");
            toast.success("Password updated successfully!");
            setIsLoading(false);
            onClickForgot();
          } else if (res.data && res.data.error) {
            setCustomErrors(res.data.error);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  const validate = async () => {
    const errors = {};
    if (!password) {
      errors.password = "Please enter your new password";
    } else if (password && !validatePassword(password)) {
      errors.password = "Please enter a valid password";
    }
    if (!password2) {
      errors.password2 = "Please enter your confirm password.";
    } else if (password2 && password !== password2) {
      errors.password2 =
        "Confirm password does not match with entered password.";
    }
    if (!verificationCode) {
      errors.verification_code = "Please enter the otp.";
    }
    setCustomErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const { handleSubmit, touched, errors, getFieldProps } = formik;

  return (
    <div className="fp-screen">
      <img src={Logo} alt="loading logo" className={"logo-icon"} />
      <h2>
        Forgot <span>Password</span>
      </h2>
      {showVerification ? (
        <Fragment>
          <p>
            Please enter the 6 digit code sent to {email} along with new
            password
          </p>
          <div className="full-part">
            <div className="input-block">
              <label>Password</label>
              <input
                type="password"
                placeholder="Enter New Password"
                className={customErrors.password && `invalid-border`}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
              />
              <span
                className={`helper-text ${
                  customErrors.password && `invalid-color`
                }`}
              >
                {customErrors.password}
              </span>
            </div>
          </div>
          <div className="full-part">
            <div className="input-block">
              <label>Confirm Password</label>
              <input
                type="password"
                placeholder="Confirm Password"
                className={customErrors.password2 && `invalid-border`}
                onChange={(e) => {
                  setPassword2(e.target.value);
                }}
                value={password2}
              />
              <span
                className={`helper-text ${
                  customErrors.password2 && `invalid-color`
                }`}
              >
                {customErrors.password2}
              </span>
            </div>
          </div>
          <div className="full-part">
            <div className="input-block">
              <label>OTP</label>
              <OtpInput
                value={verificationCode}
                onChange={(value) => setVerificationCode(value)}
                numInputs={6}
                separator={<span style={{ marginRight: "10px" }} />}
                inputStyle={{ width: "50px" }}
              />

              <span
                className={`helper-text ${
                  customErrors.verification_code && `invalid-color`
                }`}
              >
                {customErrors.verification_code}
              </span>
            </div>
            <div className="input-block">
              <button
                type="button"
                className="button-container"
                disabled={isLoading}
                onClick={submitVerification}
              >
                {isLoading && <BiLoaderAlt />} Update password
              </button>
            </div>
            <div className="sign">
              Didn’t receive code?
              {/* eslint-disable-next-line */}
              <a onClick={() => forgetSubmit(email)}>
                {timer || "Resend Code"}
              </a>
            </div>
          </div>
        </Fragment>
      ) : (
        <form onSubmit={handleSubmit}>
          <p>Please reset your account password</p>
          <div className="full-part">
            <div className="input-block">
              <label>Email</label>
              <input
                className={
                  ((errors.email && touched.email) || customErrors.email) &&
                  `invalid-border`
                }
                type="email"
                placeholder="Enter Register Email"
                {...getFieldProps("email")}
              />
              <span
                className={`helper-text ${
                  (Boolean(errors.email && touched.email) ||
                    customErrors.email) &&
                  `invalid-color`
                }`}
              >
                {(touched.email && errors.email) || customErrors.email}
              </span>
            </div>
          </div>
          <div className="input-block mt-3">
            <button disabled={isLoading} className="button-container">
              Reset Password
            </button>
          </div>
          <div className="sign">
            Back to
            <span
              onClick={onClickForgot}
              style={{ fontWeight: 700, cursor: "pointer" }}
            >
              Login
            </span>
          </div>
        </form>
      )}
    </div>
  );
};

export default Login;
