import "./../styles/create-account.scss";
import { useFormik } from "formik";
import createAccountValidation from "./../validations/create-account";
import {
  resendVerificationCode,
  signUp,
  verifyUserOtp,
} from "../service/userService";
import toast from "./../utils/toast";
import { Fragment, useState, useContext, useEffect } from "react";
import OtpInput from "react-otp-input";
import { UserContext } from "../context/user.context";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiLoaderAlt } from "react-icons/bi";
import Logo from "../assets/img/logo-white.png";
import localStorage from "../utils/localStorage";

const Register = () => {
  const { login, user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [customError, setCustomError] = useState({});
  const [verificationCode, setVerificationCode] = useState(0);
  const [userId, setUserId] = useState(0);
  const [timer, setTimer] = useState(0);
  const [showVerification, setShowVerification] = useState(false);
  const [email, setEmail] = useState("");
  const [intervalTime, setIntervalTime] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);

  useEffect(() => {
    if (queryParameters.get("redirect")) {
      if (user.isAuthenticated) {
        localStorage.removeItem("redirect");
        const token = localStorage.getItem("token");
        window.location.href = `${queryParameters.get(
          "redirect"
        )}/sso/${token}`;
      } else {
        localStorage.setItem("redirect", queryParameters.get("redirect"));
      }
    } else if (user.isAuthenticated) {
      navigate("/");
    }
  }, [user.isAuthenticated]);

  useEffect(() => {
    setShowVerification(false);
    setUserId(0);
    setVerificationCode(0);
    setTimer(0);
    setIntervalTime(null);
    setCustomError({});
  }, []);

  const formik = useFormik({
    initialValues: {
      username: "",
      name: "",
      email: "",
      password: "",
      password2: "",
      // otp: '',
    },
    validationSchema: createAccountValidation,
    onSubmit: async (values) => {
      try {
        setCustomError({});
        setIsLoading(true);
        const response = await signUp(
          queryParameters.get("r")
            ? { ...values, referral_code: queryParameters.get("r") }
            : values
        );

        if (
          response.status === 200 &&
          response.data &&
          !response.data.error &&
          response.data.id
        ) {
          toast.success("Profile created successfully");
          setUserId(response.data.id);
          setEmail(values.email);
          setShowVerification(true);
          setIsLoading(false);
        } else if (response.data && response.data.error) {
          const customError = {};
          if (response.data.error.email)
            customError.email = response.data.error.email[0];
          if (response.data.error.name)
            customError.name = response.data.error.name[0];
          if (response.data.error.username)
            customError.username = response.data.error.username[0];
          if (response.data.error.password)
            customError.password = response.data.error.password[0];
          setCustomError(customError);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.message);
        setIsLoading(false);
      }
    },
  });

  const { getFieldProps, handleSubmit, errors, touched } = formik;

  const submitVerification = async () => {
    setIsLoading(true);
    await verifyUserOtp({
      verification_code: verificationCode,
      user_id: userId,
    })
      .then(async (res) => {
        if (res.status === 200 && res.data && res.data.user) {
          toast.success("Verification completed!");
          login(res.data);
          setShowVerification(false);
          setIsLoading(false);
        } else if (res.data && res.data.error) {
          setCustomError(res.data.error);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const resendOtp = async () => {
    if (timer !== 0) {
      return;
    }
    await resendVerificationCode({ user_id: userId })
      .then((res) => {
        toast.success("Code sent successfully!");
        setTimer(60);
        let timer = 60;
        const interval = setInterval(() => {
          if (timer === 1) {
            setTimer(0);
            clearInterval(intervalTime);
            setIntervalTime(null);
          } else {
            timer--;
            setTimer(timer);
          }
        }, 1000);
        setIntervalTime(interval);
      })
      .catch((err) => {
        toast.error("Unable to Resend Code!");
        console.log(err);
      });
  };

  return (
    <div className="upload-popup-wrapper base-modal create-account">
      <div className="upload-popup-cnt">
        <div className="full">
          <div className="form-card">
            {!showVerification ? (
              <form onSubmit={handleSubmit}>
                <img src={Logo} alt="loading logo" className={"logo-icon"} />
                <h2>
                  Create an <span>Account</span>
                </h2>
                <p>Download & Upload High-Quality Content</p>
                <div className="two-part">
                  <div className="input-block">
                    <label>Username</label>
                    <input
                      className={
                        ((errors.username && touched.username) ||
                          customError.username) &&
                        `invalid-border`
                      }
                      type="text"
                      placeholder="Username"
                      {...getFieldProps("username")}
                    />
                    <span
                      className={`helper-text ${
                        (Boolean(errors.username && touched.username) ||
                          customError.username) &&
                        `invalid-color`
                      }`}
                    >
                      {touched.username && errors.username}
                    </span>
                  </div>
                  <div className="input-block">
                    <label>Your Name</label>
                    <input
                      className={
                        ((errors.name && touched.name) || customError.name) &&
                        `invalid-border`
                      }
                      type="text"
                      placeholder="Your Name"
                      {...getFieldProps("name")}
                    />
                    <span
                      className={`helper-text ${
                        (Boolean(errors.name && touched.name) ||
                          customError.name) &&
                        `invalid-color`
                      }`}
                    >
                      {(touched.name && errors.name) || customError.name}
                    </span>
                  </div>
                </div>
                <div className="full-part my-2">
                  <div className="input-block">
                    <label>Email</label>
                    <input
                      className={
                        ((errors.email && touched.email) ||
                          customError.email) &&
                        `invalid-border`
                      }
                      type="email"
                      placeholder="email@xyz.com"
                      {...getFieldProps("email")}
                    />
                    <span
                      className={`helper-text ${
                        (Boolean(errors.email && touched.email) ||
                          customError.email) &&
                        `invalid-color`
                      }`}
                    >
                      {(touched.email && errors.email) || customError.email}
                    </span>
                  </div>
                </div>
                <div className="two-part my-2">
                  <div className="input-block">
                    <label>Password</label>
                    <input
                      className={
                        ((errors.password && touched.password) ||
                          customError.password) &&
                        `invalid-border`
                      }
                      type="password"
                      placeholder="Password"
                      {...getFieldProps("password")}
                    />
                    <span
                      className={`helper-text ${
                        (Boolean(errors.password && touched.password) ||
                          customError.password) &&
                        `invalid-color`
                      }`}
                    >
                      {(touched.password && errors.password) ||
                        customError.password}
                    </span>
                  </div>
                  <div className="input-block">
                    <label>Confirm Password</label>
                    <input
                      className={
                        errors.password2 &&
                        touched.password2 &&
                        `invalid-border`
                      }
                      type="password"
                      placeholder="Confirm Password"
                      {...getFieldProps("password2")}
                    />
                    <span
                      className={`helper-text ${
                        Boolean(errors.password2 && touched.password2) &&
                        `invalid-color`
                      }`}
                    >
                      {touched.password2 && errors.password2}
                    </span>
                  </div>
                </div>

                <div className="form-check my-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    By continuing, you agree to the{" "}
                    <a target={"_blank"} href="https://creatorstock.io/terms">
                      Terms of use
                    </a>{" "}
                    and
                    <a
                      target={"_blank"}
                      href="https://creatorstock.io/privacy-policy"
                    >
                      Privacy Policy
                    </a>
                    , of CreatorStock.
                  </label>
                </div>

                <div className="input-block">
                  <button
                    type="submit"
                    className="button-container"
                    disabled={!isChecked || isLoading}
                  >
                    {isLoading && <BiLoaderAlt />}
                    Sign Up
                  </button>
                </div>
                <div className="sign">
                  Already have an account?
                  {/* eslint-disable-next-line */}
                  <Link to="/">Login</Link>
                </div>
              </form>
            ) : (
              <Fragment>
                <h2>
                  Account <span>Verification</span>
                </h2>
                <p>Please enter the 6 digit code sent to {email}</p>
                <div className="full-part">
                  <div className="input-block" style={{ margin: "0 auto" }}>
                    <OtpInput
                      value={verificationCode}
                      onChange={(value) => setVerificationCode(value)}
                      numInputs={6}
                      renderSeparator={<span style={{ marginRight: "10px" }} />}
                      inputStyle={{ width: "50px" }}
                      renderInput={(props) => <input {...props} />}
                    />

                    <span
                      className={`helper-text ${
                        customError.verification_code && `invalid-color`
                      }`}
                    >
                      {customError.verification_code}
                    </span>
                  </div>
                  <div
                    className="input-block action-col mt-3"
                    style={{ margin: "0 auto" }}
                  >
                    <button
                      type="button"
                      className="button-container"
                      disabled={isLoading}
                      onClick={submitVerification}
                    >
                      {isLoading && <BiLoaderAlt />} Verify
                    </button>
                  </div>
                  <div className={"resendBtn"}>
                    Didn’t receive code?
                    <a onClick={resendOtp}>{timer || " Resend Code"}</a>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
