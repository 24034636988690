import React from "react";
import "./../../styles/main-loader.scss";
import Loader from "./../../assets/img/loader.gif";

const MainLoader = () => {
  return (
    <div className="loader-container">
      <img src={Loader} alt="loading logo" />
    </div>
  );
}

export default MainLoader;
